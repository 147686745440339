<template>
  <ul class="team-lisout">
    <li class="teamls" :class="clsname">
      <div class="iconfont" @click="showChild(item)">{{item.silder?'&#xe655;':'&#xe65f;'}}</div>
      <div>ID:<span>{{item.id}}</span></div>
      <div>昵称:<span>{{item.wx_name||'--'}}</span></div>
      <div>手机:<span>{{item.user_phone}}</span></div>
      <div>性别:<span>{{item.gender||''}}</span></div>
      <div>等级:<span>{{cfg.level[item.level_id]||'--'}}</span></div>
      <div v-for="(p,k) in item.ninfo" :key="k">{{cfg.platform[p.pid]||''}}UID：<span>{{p.num||'--'}}</span></div>
      <template v-if="item.silder && item.children">
        <team-infinite v-for="(cdata,index) in item.children" :item="cdata" :key="index" clsname="borl" :cfg="cfg"></team-infinite>
      </template>
    </li>
  </ul>
</template>

<script>
	export default {
		name: "TeamInfinite",
    props:{
      item:{
        type:Object,
        default:function (){
          return {};
        }
      },
      clsname:{
        type:String,
        default:''
      },
      cfg:{
        type:Object,
        default:function (){
          return {};
        }
      }
    },
		data() {
			return { }
		},
		methods: {
      /**
       * 展开收缩下级
       */
      showChild:function (data){
        var _this=this;
        if(data.silder===false || typeof (data.silder)=="undefined"){
          if(data.children){
            _this.$set(data,'silder',true);
          }else{
            _this.requestApi('/adm/teamlist.html',{'parent_uid':data.id}).then(function (res){
              _this.$set(data,'children',res.data);
              _this.$set(data,'silder',true);
            })
          }
        }else if(data.silder===true){
          this.$set(data,'silder',false);
        }
      }
		}
	}
</script>
<style lang="less">
.team-lisout {
  list-style: none;
  .teamls {
    padding: 2px 0 2px 20px;
    font-size: 12px;
    line-height: 18px;
    list-style: none;
    div {
      display: inline-block;
      padding-right: 10px;

      span {
        color: #2d8cf0;
      }
    }

    .iconfont {
      display: inline-block;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
      -ms-user-select: none;
      user-select: none;
    }
    &.borl{border-left: 1px dashed #C5C5C5;}
  }
}
</style>