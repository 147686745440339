<template>
  <div class="teamlist">
    <div class="searchw">
      <Form inline :label-width="52">
        <FormItem label="用户ID" :label-width="53" style="width: 150px">
          <Input maxlength="10" v-model="posdata.id" @on-keyup.13="init"></Input>
        </FormItem>
        <FormItem label="用户手机" :label-width="66" style="width: 166px">
          <Input maxlength="11" v-model="posdata.user_phone" @on-keyup.13="init"></Input>
        </FormItem>
        <FormItem label="用户昵称" :label-width="66" style="width: 166px">
          <Input maxlength="30" v-model="posdata.wx_name" @on-keyup.13="init"></Input>
        </FormItem>
        <FormItem label="" :label-width="0">
          <Button type="default" icon="ios-search" @click="init" @keyup.13="init">搜索</Button>
        </FormItem>
      </Form>
    </div>
    <div class="teamout">
      <team-infinite v-for="(cdata,index) in data" :item="cdata" :key="index" :cfg="cfg"></team-infinite>
    </div>
    <Spin size="large" fix v-if="page_loading"></Spin>
  </div>
</template>
<script>
import TeamInfinite from "@/components/teamlist/teaminfinite"
export default {
  name: 'TeamList',
  components:{TeamInfinite},
  data(){
    return {
      data:[],
      cfg:{platform: {},level:{}},
      page_loading:false,
      posdata:{
        id:'',
        user_phone:'',
        wx_name:''
      }
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (){
      var _this=this;
      this.page_loading=true;
      this.requestApi('/adm/teamlist.html',this.posdata).then(function (res){
        _this.page_loading=false;
        if(res.status==1){
          _this.cfg=res.cfg;
          _this.data=res.data;
        }
      })
    },
    /**
     * 展开收缩下级
     */
    showChild:function (data){
      var _this=this;
      if((data.silder===false || (typeof data.silder)=="undefined") && data.teamuser){
        _this.requestApi('/adm/teamlist.html',{'parent_uid':data.id}).then(function (res){
          console.log(res)
        })
      }else if(data.silder===true){
        this.$set(data,'silder',false);
      }
    }
  }
}
</script>
<style lang="less">
.teamlist{
  position: relative;
  .teamout {
    width: 100%;
    height: auto;
    padding: 10px 0;
  }
}
</style>